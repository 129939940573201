<template>
  <div class="container">
    <template v-if="desc.content_type == 3">
      <!-- <av-circle
        v-if="audioUrl"
        :outline-width="0"
        :progress-width="5"
        :outline-meter-space="5"
        :playtime="true"
        :line-width="2"
        line-color="lime"
        playtime-font="18px Monaco"
        :audio-src="audioUrl"
        class="container-audio"
      /> -->
      <div style="text-align:center;padding:5px 0">
        <audio ref='audios' controls="controls"></audio>
      </div>
      

    </template>
    <template v-if="desc.content_type == 2">
    </template>
    <van-tabs v-model="tabActive">
      <van-tab title="简介">
        <div class="desc">
          <div class="desc-hd">
            <div class="desc-hd-h2">{{ desc.content_name }}</div>
            <div class="desc-hd-bottom">
              <div class="desc-hd-bottom-hd">
                归类：{{ desc.content_type_name }}
              </div>
              <div class="desc-hd-bottom-bd">
                长度：{{ formatTime(desc[typeMap[desc.content_type]] || 0) }}
              </div>
            </div>
          </div>
          <div class="desc-bd">
            <div class="desc-brief">
              <div class="desc-brief-h2">
                课程介绍
              </div>
              <div class="desc-brief-content article-content" v-html="desc.content" />
            </div>
          </div>
        </div>
      </van-tab>
      <van-tab title="PPT">
        <div class="ppt">
          <van-swipe :autoplay="3000" class="swiper" indicator-color="#00de93">
            <van-swipe-item v-for="item in pptList" :key="item.id">
              <van-image :src="item.thumb" alt="banner" />
            </van-swipe-item>
          </van-swipe>
        </div>
      </van-tab>
      <van-tab title="评论">
        <div class="comment">
          <van-list v-model="listLoading" class="list" :finished="listFinished" :finished-text="listFinishedText" @load="onListLoad">
            <div v-for="item in list" :key="item.content_id" class="list-item mb10">
              <div class="list-item-header van-hairline--bottom">
                <div class="list-item-avatar"><van-image class="list-item-avatar-img" :src="item.commnet_avatar" alt="" /></div>
                <div class="list-item-inner">
                  <div class="list-item-name">{{ item.commnet_name }}</div>
                  <div v-if="item.commnet_rate" class="list-item-star">
                    <van-rate
                      :value="item.commnet_rate"
                      :size="14"
                      :color="globalData.theme.color1"
                      allow-half
                      void-icon="star"
                      void-color="#eee"
                      readonly
                    />
                  </div>
                </div>
                <div class="list-item-date">{{ item.comment_at }}</div>
              </div>
              <div class="list-item-content"><p>{{ item.commnet_content }}</p></div>
              <div v-if="item.comment_image.length" class="list-item-images">
                <van-image
                  v-for="(image,imageIndex) in item.comment_image"
                  :key="imageIndex"
                  class="list-item-image"
                  :src="image"
                />
              </div>
            </div>
          </van-list>
        </div>
      </van-tab>
    </van-tabs>

    <!-- affix-bar -->
    <div v-if="tabActive === 2" class="affix-bar">
      <van-button
        class="affix-bar-btn"
        block
        type="primary"
        @click="commentVisible = true"
      >立即评论</van-button>
    </div>
    <!-- 评价弹层 -->
    <van-popup v-model="commentVisible" position="bottom">
      <div class="popup">
        <van-icon name="close" class="popup-close" @click="commentVisible = false" />
        <div class="popup-body">
          <div class="popup-title">发表评价</div>
          <!-- <van-cell title="请选择您的评分" :border="false" /> -->
          <!-- <van-field name="rate" allow-half label="评分">
            <template #input>
              <van-rate
                v-model="rate"
                allow-half
                void-icon="star"
                void-color="#eee"
                :color="globalData.theme.color1"
              />
            </template>
          </van-field> -->
          <van-field
            v-model="remark"
            :border="false"
            label="内容"
            type="textarea"
            placeholder="非常棒的一堂课！"
            rows="1"
            autosize
          />
          <van-field label="图片">
            <template #input>
              <van-uploader v-model="uploaImgs" :max-count="3" :after-read="afterRead" />
            </template>
          </van-field>
        </div>
        <div class="popup-bottom">
          <van-button class="popup-confirm" type="primary" size="large" @click="onSubmit">提交评价</van-button>
        </div>
      </div>
    </van-popup>

    <!-- 测试 -->
    <!-- <audio :src="audioUrl" controls="controls"></audio> -->


  </div>
</template>
<script>
import { Tab, Tabs, Field, Uploader, Swipe, SwipeItem } from 'vant'

// import VueDPlayer from 'vue-dplayer'

// import 'vue-dplayer/dist/vue-dplayer.css'
import upload from '@/utils/upload'
import PageMixin from '@/mixins/page'
import { isEmpty } from '@/common/validate'
import { formatTime } from '@/common/util'

export default {
  name: 'ContentClassDetail',
  components: {
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Field.name]: Field,
    [Uploader.name]: Uploader,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem
  },

  mixins: [PageMixin],
  data() {
    return {
      playerOtions: {
        url: '',
        pic: ''
      },
      audioUrl: null,
      player: null,
      tabs: [],
      tabActive: 0,
      desc: {},
      pptList: [],
      // commentList: [],
      commentVisible: false,
      remark: '',
      uploaImgs: [],
      list: [],
      listLoading: false,
      listFinished: false,
      listFinishedText: '没有更多了',
      pageSize: 20,
      pageCurrent: 1,
      typeMap: {
        '1': 'content_mp3_duration',
        '2': 'content_video_duration',
        '3': 'content_mp3_duration'
      }

    }
  },
  created() {
    this.onDetail()
  },
  methods: {
    formatTime,
    onDetail() {
      this.$api.content_detail({ content_id: this.$route.query.content_id }).then(res => {
        this.desc = res.data
        this.desc.content_title = '11'
      })
      this.$api.content_play({ content_id: this.$route.query.content_id }).then(res => {
        this.playerOtions.url = res.data.playuri
        if (Number(this.desc.content_type) === 2) {
          this.$nextTick(() => {
            this.player = this.$refs.player.dp
            this.player.switchVideo({
              url: res.data.playuri
            })
          })
        } else {
          this.audioUrl = res.data.playuri
          this.$refs.audios.src = res.data.playuri
        }
      })
      this.$api.content_ppt({ content_id: this.$route.query.content_id }).then(res => {
        this.pptList = res.data
      })
    },
    onListLoad() {
      this.onPage()
    },
    onPage() {
      this.$api.content_comment({ content_id: this.$route.query.content_id, 'per-page': this.pageSize, page: this.pageCurrent })
        .then(res => {
          this.listLoading = false
          this.list = Number(res._meta.currentPage) === 1 ? res.items : this.list.concat(res.items)
          this.listFinished = this.list.length >= res._meta.totalCount
          this.listFinishedText = this.list.length ? '没有更多了' : '暂无相关数据~'
          this.pageCurrent += 1
        })
    },
    afterRead(file, detail) {
      file.status = 'uploading'
      file.message = '上传中...'
      upload({ file: file.file })
        .then(res => {
          this.uploaImgs.splice(detail.index, 1, { url: res.path, status: 'done', message: '上传成功' })
        })
        .catch(err => {
          console.error(err)
          file.status = 'failed'
          file.message = '上传失败'
        })
    },
    onSubmit() {
      if (isEmpty(this.remark)) {
        this.$toast('请填写评论内容')
        return
      }
      this.$toast.loading({
        message: '提交中...',
        forbidClick: true
      })
      const params = {
        comment_entity: this.desc.comment_entity,
        comment_content: this.remark,
        comment_image: this.uploaImgs.map(item => item.url)
      }

      this.$api.content_addcomment(params)
        .then(res => {
          this.$toast.success('提交评价成功')
          setTimeout(() => {
            this.$router.back()
          }, 1500)
        })
        .catch(err => {
          this.$toast.fail('提交失败')
          console.error(err)
        })
    }
  }
}
</script>

<style lang="less" scoped>

    .popup {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      min-height: 300px;
      &-close {
        position: absolute;
        top: 10px;
        right: 15px;
        z-index: 20;
        color: #969799;
        font-size: 20px;
        text-align: center;
      }
      &-body {
        width: 100%;
        flex: 1;
      }
      &-title {
        font-size: 16px;
        text-align: center;
        padding: 10px 0;
      }
      &-bottom {
        width: 100%;
      }
      .rate-box {
        padding: 0px 15px;
        margin-bottom: 30px;
      }
    }

     .desc{
    &-hd{
      padding: 10px 15px;
      margin-bottom: 10px;
      background: #fff;
      &-h2{
        font-size: 18px;
        line-height: 2;
        font-weight: bold;
        margin-bottom: 10px;
      }
      &-bottom{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-size: 12px;
        color: #666;
        &-hd{
          margin-right: 20px;
        }
        &-bd{
          // font-size: 10px;
          // color: #f44;
        }
      }
    }
    &-brief{
      padding: 10px 15px;
      background: #fff;
      &-h2{
        font-size: 16px;
        line-height: 2;
        font-weight: bold;
        margin-bottom: 10px;
      }
      &-content{
        font-size: 14px;
        color: #333;
        word-wrap : break-word 
      }
    }
  }

  .list-item{
    background:#fff;
    &-avatar{
      margin-right:5px;
      &-img{
        width:40px;
        height:40px;
        border-radius: 50%;
        overflow: hidden;
      }
    }
    &-name{
      font-size: 14px;
      color:#555;
      line-height: 1;
    }
    &-inner{
      flex:1;
      padding-top:4px;
    }
    &-date{
      font-size: 12px;
      color:#999;
    }
    &-header{
      padding:10px 15px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
    &-content{
      padding:10px 15px;
      color:#999;
      font-size: 14px;
    }
    &-images {
      padding:0 15px 10px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
    &-image {
      width: 90px;
      // height: 90px;
      margin-right: 10px;
    }
  }
</style>

<style lang="less">
  .container-audio{
    div{
      text-align: center;
    }
  }
</style>

